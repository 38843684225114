/* Spinner */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: var(--clear-blue);
  font-size: 10px;
  margin: 0 auto 25px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* Help button */
.help-link {
  margin-top: 1rem !important;
  color: var(--clear-blue) !important;
}

/* Advance banner styles */
.content_banner {
  clear: both;
  margin: 2rem 0;
}

.content_banner .center_content {
  background: var(--clear-blue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  float: none;
}

.content_banner .center_content .text {
  color: #fff;
}

.content_banner .center_content a {
  color: #fff;
  font-weight: bold;
}

/* Mobile request buttons */
@media (max-width: 1000px) {
  .dashboard .card .content_button {
    height: 135px;
  }

  .help-link {
    color: #fff !important;
    z-index: 3;
  }

  .whatssapp_logo_sub_container {
    z-index: 3;
  }

  .whatssapp_logo_container {
    margin-top: 1rem !important;
  }

  .content_banner {
    margin: 1rem;
  }

  .content_banner .center_content a {
    margin-left: 1rem;
  }
}

.step_04 .content_buttons .button {
  width: 170px;
}

.step_04 .content_buttons .button:first-child {
  float: left;
}

.step_04 .content_buttons .button:last-child {
  float: right;
}

.custom-logo {
  display: block;
  text-align: center;
}

.custom-logo img {
  width: 100px;
}

.forgot-password-btn {
  margin-bottom: 2rem;
  float: left;
  color: var(--clear-blue);
}

input:disabled {
  background: var(--grey-02);
  cursor: not-allowed;
}

input[type='radio']:disabled {
  cursor: not-allowed !important;
}

input[type='radio']:disabled + .label_radio {
  background: var(--grey-02);
}

input[type='radio']:disabled ~ .text {
  color: var(--warm-grey);
}

a.disabled {
  border: none;
  background: var(--grey) !important;
  cursor: not-allowed;
}

a.disabled:hover {
  box-shadow: none !important;
}

a.disabled span {
  color: var(--warm-grey) !important;
}

input.amount {
  font-size: 1.125rem;
}

input.amount::placeholder {
  font-size: 0.875rem;
}

/* .navigation .center_content .logo, */
.navigation .center_content .content_links {
  height: 50px;
}

.navigation .center_content .content_links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-check .text {
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--greyish);
  display: block;
  padding: 0 0 0 45px;
}

.label-check .text a {
  color: var(--clear-blue);
}

.warning .label-check span.label_check {
  background: var(--red);
}

.terms-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.terms-close,
.close-modal {
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
}

.terms-frame {
  flex: 1;
  margin-top: 3rem;
}

.step_04 .circle-wrap .circle .mask.full,
.step_04 .circle-wrap .circle .fill {
  animation: fill_step_05 ease-in-out 1.5s;
  transform: rotate(135deg);
}

.step_05 .circle-wrap .circle .mask.full,
.step_05 .circle-wrap .circle .fill {
  animation: fill_step_06 ease-in-out 1.5s;
  transform: rotate(180deg);
}

.success-text {
  text-align: center;
  margin-top: 2rem;
}

/* Custom native select (inherited) */
.custom_native_select {
  background: var(--grey-02);
  height: 50px;
  float: left;
  border-radius: 8px;
}

.custom_native_select::before {
  width: 16px;
  height: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
  background: url(../../img/bg_arrow_blue.svg) no-repeat;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  will-change: transform;
  transform-origin: center;
  content: '';
}

.custom_native_select select {
  height: 50px;
  border: none;
  background: transparent;
  padding: 0 50px 0 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--greyish);
}

.advance h3 {
  font-size: 1.5rem;
}

.advance.card .content_form li {
  margin-top: 2rem;
}

.advance.card label {
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 0 12px;
}

/*Input*/
.advance.card input {
  background: var(--grey-02);
  font-weight: 600;
  font-size: 1rem;
}

.form_error {
  text-align: center;
  margin-top: 2rem;
  color: var(--red);
}

.advance .content_button {
  margin-top: 32px !important;
}

.link {
  color: var(--clear-blue);
}

.full-page-center {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.blocked_period_message {
  z-index: 3;
}

.blocked_period_message span {
  background: var(--grey-02);
  border-radius: 100%;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  margin-left: 5px;
  padding: 4px 8px;
}

.blocked_period_message span:hover {
  background: var(--grey);
}

.salary-payment {
  padding: 1rem;
}

.salary-payment h1 {
  font-size: 1.8rem;
  font-weight: normal;
}

.salary-payment div {
  margin-top: 1.5rem;
}

.salary-payment a {
  color: var(--clear-blue);
}

.salary-payment button {
  border: none;
  border-radius: 20px;
  background-color: var(--clear-blue);
  color: #fff;
  font-size: 1rem;
  margin: 2rem auto 0 auto;
  display: block;
  padding: 0.8rem 1.6rem;
  outline: none;
}

.salary-modal {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 40px;
  left: 0;
  right: 0;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  max-width: 400px;
  width: 80%;
}

.navigation {
  padding-bottom: 2rem;
}

.navigation .center_content:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation .center_content:last-child {
  display: flex;
  margin-top: 3rem;
}

.user_profile_card {
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05), 0 14px 12px 0 rgba(133, 106, 143, 0.1);
  padding: 1rem 2rem;
}

.user_profile_image {
  display: none;
  height: 40px;
  width: 40px;
}

.user_profile_image img {
  border-radius: 50%;
  width: 100%;
}

.user_profile_name {
  color: var(--clear-blue);
  font-weight: bold;
}

.programmed_requests {
  padding: 3rem 1rem !important;
  text-align: center;
}

.programmed_requests h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.programmed_requests .requests a {
  margin-left: 1rem;
}

.empty_requests a {
  margin-top: 1rem;
}

.programmed_request_wizard h1 {
  font-size: 1.5rem;
}

.programmed_request_wizard .content_form li {
  margin-top: 2rem;
}

.programmed_request_wizard label {
  padding-bottom: 1rem;
}

.programmed_request_wizard input {
  background: var(--grey-02);
  font-weight: 600;
  font-size: 1rem;
}

.programmed_request_wizard .content_button {
  margin-top: 3rem !important;
}

.programmed_request_wizard .messages {
  margin-top: 1rem;
}

.programmed_requests .buttons,
.programmed_request_wizard .content_button {
  margin-top: 2rem !important;
  display: flex;
  justify-content: center;
}

/* Loading button */
.loading_btn,
.disabled_full_btn {
  border-color: #94c8ff;
  background: #94c8ff !important;
  cursor: default;
}

.loading_btn:hover,
.disabled_full_btn:hover {
  box-shadow: none !important;
}

.loading_btn span {
  margin-right: 0.8rem;
}

.disabled_border_btn {
  border-color: #f4f4f4;
  background: #f4f4f4 !important;
  cursor: default;
}

.disabled_border_btn:hover {
  box-shadow: none !important;
}

.disabled_border_btn span:last-child {
  color: #ccc !important;
}

.wizard_error_msg {
  color: var(--red);
  margin-top: 1rem;
}

.date .advanced {
  font-weight: bold;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: var(--clear-blue);
}

@media (max-width: 1000px) {
  .dashboard .card .content_transfer_money {
    padding-bottom: 170px;
  }

  .dashboard .card .content_button {
    height: 160px;
  }

  .request_button_message {
    background: #fff;
    border-radius: 25px;
    padding: 1rem 2rem;
  }

  .navigation .center_content:first-child {
    justify-content: space-around;
  }

  .navigation .center_content:first-child .logo {
    margin: 0;
  }

  .navigation .center_content:last-child {
    margin-top: 1rem;
  }

  .user_profile_card {
    padding: 0.25rem;
  }

  .user_profile_image {
    display: block;
    height: 30px;
    width: 30px;
  }

  .user_profile_name {
    display: none;
  }

  .programmed_requests .buttons,
  .programmed_request_wizard .content_button {
    flex-direction: column;
  }

  .programmed_request_wizard .content_button .button {
    width: 100% !important;
  }

  .programmed_requests .button:last-child,
  .programmed_request_wizard .content_button .button:last-child {
    margin-top: 1rem;
  }

  .content_button .button:last-child {
    order: -1 !important;
    margin-top: 0 !important;
  }

  .content_button .button:first-child {
    margin-top: 1rem !important;
  }
}

.icon_white {
  color: white;
  margin-right: 8px;
}

@media (min-width: 1000px) {
  .balance_home {
    padding-bottom: 30px !important;
  }
}

.whatssapp_logo_container {
  margin-top: 9px !important;
  display: flex;
  justify-content: flex-end;
}

.whatssapp_logo_sub_container {
  display: flex;
  justify-content: flex-end;
}

.whatssapp_logo {
  width: 60px;
  height: 60px;
}

.programmed_requests_balance_home {
  color: var(--white) !important;
  background: var(--clear-blue);
  height: 26px;
  border-radius: 32px;
  align-items: center;
  float: left;
}

@media (min-width: 1000px) {
  .programmed_requests_balance_home {
    margin-bottom: 6px !important;
    padding: 0 10px;
  }
}

@media (max-width: 1000px) {
  .programmed_requests_balance_home {
    margin: 0 6px 5px 6px !important;
    width: 98%;
  }
  .request_button_message {
    padding: 1rem 1rem;
  }
  .request_button_message_text {
    font-size: smaller;
  }
}
