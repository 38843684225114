/*-------------------------------------------------------------------------------
	HTML5 Reset :: style.css for Ismael Salamano
	----------------------------------------------------------
	We have learned much from/been inspired by/taken code where offered from:
	
	Eric Meyer					:: http://ericmeyer.com
	HTML5 Doctor				:: http://html5doctor.com
	and the HTML5 Boilerplate	:: http://html5boilerplate.com 
-------------------------------------------------------------------------------*/

/* Let's default this puppy out
-------------------------------------------------------------------------------*/

:root {
  --white: #ffffff;
  --white-02: rgba(250, 250, 250, 0.2);
  --white-04: rgba(250, 250, 250, 0.4);
  --white-06: rgba(250, 250, 250, 0.6);
  --white-08: rgba(250, 250, 250, 0.8);

  --black: #000000;
  --black-02: rgba(0, 0, 0, 0.2);
  --black-04: rgba(0, 0, 0, 0.4);
  --black-06: rgba(0, 0, 0, 0.6);
  --black-08: rgba(0, 0, 0, 0.8);

  --bg-general: #f4f6ff;

  --clear-blue: #2a91ff;

  --greyish: #525252;
  --light-blue-grey: #e3ecf8;
  --warm-grey: #969696;
  --soft-grey: rgba(210, 210, 210, 0.5);
  --grey-02: rgba(216, 216, 216, 0.5);
  --grey: #d2d2d2;

  --yellow: #ffdc6a;

  --green: #bbe054;

  --red: #eb4d4d;

  --warning-input: rgba(255, 125, 125, 0.2);
}

html,
body,
body div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article,
aside,
figure,
footer,
header,
hgroup,
nav,
section {
  display: block;
}

/* Responsive images and other embedded objects
   Note: keeping IMG here will cause problems if you're using foreground images as sprites.
   If this default setting for images is causing issues, you might want to replace it with a .responsive class instead. */
img,
object,
embed {
  max-width: 100%;
}

/* force a vertical scrollbar to prevent a jumpy page */
html {
  /*overflow-y: scroll;*/
}

/* we use a lot of ULs that aren't bulleted. 
	don't forget to restore the bullets within content. */
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 1em;
  vertical-align: baseline;
  background: transparent;
}

del {
  text-decoration: line-through;
}

abbr[title],
dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help;
}

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0;
}
table {
  font-size: inherit;
  font: 1em;
}
th {
  font-weight: bold;
  vertical-align: bottom;
}
td {
  font-weight: normal;
  vertical-align: top;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid var(--gray);
  margin: 1rem 0;
  padding: 0;
}

pre {
  white-space: pre; /* CSS2 */
  white-space: pre-wrap; /* CSS 2.1 */
  white-space: pre-line; /* CSS 3 (and 2.1 as well, actually) */
  word-wrap: break-word; /* IE */
}

/* Accessible focus treatment
	people.opera.com/patrickl/experiments/keyboard/test */
a:hover,
a:active {
  outline: none;
}

/*Focus*/
*:focus {
  outline: none;
}

/*Form*/

input,
select {
  vertical-align: middle;
}

input[type='radio'] {
  vertical-align: text-bottom;
}
input[type='checkbox'] {
  vertical-align: bottom;
  *vertical-align: baseline;
}
.ie6 input {
  vertical-align: text-bottom;
}

select,
input,
textarea {
  font: 1em sans-serif;
}

/*Remove AutoFIll*/
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
  -webkit-box-shadow: 0 0 0 1000px var(--white) inset !important;
  -webkit-text-fill-color: var(--gray) !important;
}

/*PlaceHolder*/
input::-webkit-input-placeholder {
  /* Edge */
  /*color: transparent;*/
}

input:-ms-input-placeholder {
  /* Internet Explorer */
  /*color: transparent;*/
}

input::-ms-input-placeholder {
  /*color: transparent;*/
}

input::placeholder {
  /*color: transparent;*/
}

textarea::-webkit-input-placeholder {
  /* Edge */
  /*color: transparent;*/
}

textarea:-ms-input-placeholder {
  /* Internet Explorer */
  /*color: transparent;*/
}

textarea::-ms-input-placeholder {
  /*color: transparent;*/
}

textarea::placeholder {
  /*color: transparent;*/
}

input:invalid {
  box-shadow: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /*-webkit-appearance:none; margin:0;*/
}

input[type='number'] {
  /*-moz-appearance:textfield;*/
}

/**/
small {
  font-size: 85%;
}

strong,
th {
  font-weight: bold;
}

td,
td img {
  vertical-align: top;
}

p,
span {
  -webkit-text-size-adjust: none;
}

/* Make sure sup and sub don't screw with your line-heights
	gist.github.com/413930 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}

/* standardize any monospaced elements */
pre,
code,
kbd,
samp {
  font-family: monospace, sans-serif;
}

/* hand cursor on clickable elements */
.clickable,
input[type='button'],
input[type='submit'],
button {
  cursor: pointer;
}

/* Webkit browsers add a 2px margin outside the chrome of form elements */
button,
input,
select,
textarea {
  margin: 0;
}

/* make buttons play nice in IE */
button {
  width: auto;
  overflow: visible;
}

/* scale images in IE7 more attractively */
.ie7 img {
  -ms-interpolation-mode: bicubic;
}

/* prevent BG image flicker upon hover */
.ie6 html {
  -webkit-filter: expression(document.execCommand('BackgroundImageCache', false, true));
          filter: expression(document.execCommand('BackgroundImageCache', false, true));
}

/* let's clear some floats */
.clearfix:before,
.clearfix:after {
  content: '\0020';
  display: block;
  height: 0;
  overflow: hidden;
}
.clearfix:after {
  clear: both;
}
.clearfix {
  zoom: 1;
}

/* Ok, this is where the fun starts.
-------------------------------------------------------------------------------*/

/* new Linux- and Windows-friendly sans-serif font stack: http://mky.be/fontstack */
body {
  font: 16px Arial, Helvetica, sans-serif;
}

/* using local fonts? make sure to read up on Paul Irish's 
	Bulletproof @font-face syntax: http://mky.be/font-face/bulletproof/ */

/* we like off-black for text */
body,
select,
input,
textarea {
  color: #525252;
  color: var(--greyish);
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0px;
}

a {
  color: #525252;
  color: var(--greyish);
}
a:hover {
  color: #525252;
  color: var(--greyish);
}

/* Custom text-selection colors (remove any text shadows: twitter.com/miketaylr/status/12228805301) */
::selection {
  background: #2a91ff;
  background: var(--clear-blue);
  color: #ffffff;
  color: var(--white);
  text-shadow: none;
}

/*	j.mp/webkit-tap-highlight-color */
a:link {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.2);
  -webkit-tap-highlight-color: var(--black-02);
}

ins {
  background-color: #fcd700;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #fcd700;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

@charset "utf-8";
/* CSS Document */


/*FONTS*/


/*GENERICS*/

html{width:100%; height:100%}

body{width:100%; height:100%; background:var(--bg-general); font-size:1em; font-family:'Open Sans', Arial, Helvetica, sans-serif; font-weight:400; color:var(--greyish); -webkit-font-smoothing: antialiased; /*-webkit-transform: translate3d(0,0,0);  -moz-transform: translate3d(0,0,0); transform: translate3d(0,0,0);*/}

*:focus {outline: none;}

a{color:var(--black); text-decoration:none; transition: all 0.35s ease-in-out;}

header, section, article, aside, footer, nav, figure, div, a{box-sizing:border-box; -webkit-box-sizing:border-box; -moz-box-sizing:border-box; position:relative;}

	/*Form*/

		/*Input*/
		input{height:50px; border:none; padding:0 40px 0 15px;; border-radius:8px; box-sizing:border-box; font-family:'Open Sans', sans-serif; font-weight:400; font-size:0.875rem; color:var(--warm-grey);}

			input[type="number"]{background:var(--white);}

			input[type="password"],
			input.password[type="text"]{background:var(--grey-02); font-weight:600; font-size:1rem;}

		/*Select*/
		.select_style{height:50px; border-bottom:var(--green) 1px solid; border-right:none; border-left:none; border-top:none;}
		.select_style::before{width:17px; height:17px; position:absolute; top:0; bottom:0; right:0; margin:auto 0; background:url(/static/media/bg_arrow.c52709d6.svg) no-repeat; pointer-events:none; content:''}

			.select_style select{border:none; background:var(--white); height:100%; color:var(--black); box-sizing:border-box; -webkit-box-sizing:border-box;}

		/*Checkbox*/
		.label-check {width:auto; float:left; position:relative; cursor:pointer; margin:0;padding:0!important;}
	    .label-check input[type="checkbox"] { opacity:0; position:absolute; left:0; top:0; margin:0; z-index:1; cursor:pointer; width:24px; height:24px;}
	    .label-check span.label_check { float:left;position:relative; top:-2px; background:var(--grey-02); display:inline-block; vertical-align:bottom; margin-right:5px; width:24px; height:24px; border-radius:2px; box-shadow:inset 0 2px 1px 0 rgba(0, 0, 0, 0.1);}
	    .label-check input[type="checkbox"]:checked + span:after { content:''; display:block; position:absolute; left:0; top:0; right:0; bottom:0; margin:auto; width:24px; height:24px; background-image:url(/static/media/bg_check.e58d1986.svg); background-color:transparent; background-repeat:no-repeat; border-radius:2px; background-position:center;}

	    /*Big Radio*/
	    .big-label-radio {width:100%; height:40px; float:left; position:relative; cursor:pointer; margin:0; z-index:3;}
	    .big-label-radio input[type="radio"] { opacity:0; position:absolute; left:0; top:0; margin:0; z-index:1; cursor:pointer; width:100%; height:40px;}
	    .big-label-radio span.label_radio { position:absolute; top:0; right:0; background:var(--white); display:inline-block; vertical-align:bottom; width:100%; height:40px; border-radius:8px;}
	    .big-label-radio input[type="radio"]:checked + span:after { content:''; display:block; position:absolute; left:0; top:0; right:0; bottom:0; margin:auto; width:100%; height:40px; background:var(--clear-blue); border-radius:8px;}
	    .big-label-radio span.text{width:100%; position:absolute; top:7px; left:0; font-weight:700; font-size:1.125rem; text-align:center; color:var(--clear-blue); box-sizing:border-box;}
	    .big-label-radio input[type="radio"]:checked ~ span.text{color:var(--white);}

	    /*Warning/Success*/

			/*Warning Input*/
			.warning input{background:var(--warning-input) !important; color:var(--red);}

			/*Msg*/
			.msg_form{display:none; font-weight:700; font-size:0.750rem; margin:10px 0 0; padding:1px 0 0 25px; position:relative;}

				.msg_form::before{width:20px; height:20px; position:absolute; top:0; left:0; background:url(/static/media/sp_msg_form.6fe0fd60.svg) no-repeat; content:'';}

				/*Warning*/
				.warning .msg_form{display:block; color:var(--red);}

					.warning .msg_form::before{background-position:0px -27px;}

				/*Success*/
				.success .msg_form{display:block; color:var(--green);}

					.success .msg_form{background-position:0px 0px;}

		/*Eye*/
		.show_hide_pass{width:30px; height:30px; position:absolute; top:10px; right:10px; z-index:3; background:url(/static/media/sp_show_password.0575cf88.svg) no-repeat; font-size:0; transition:none; -webkit-transition:none;}
		.show_hide_pass.open{background-position:0px 3px;}
		.show_hide_pass.close{background-position:0px -25px;}

	/*Card*/
	.card{background:var(--white); border-radius:40px; box-shadow:0 2px 24px 0 rgba(0, 0, 0, 0.05), 0 14px 12px 0 rgba(133, 106, 143, 0.1);}

	/*Button*/
	.button{height:50px; padding:0 25px; line-height:50px; display:inline-block; text-align:center; border-radius:25px; text-align:center; box-sizing:border-box; border:var(--clear-blue) 1px solid; will-change:background;}

		.button span{font-weight:600; font-size:1.125rem; transition:all 0.35s ease-in-out; -webkit-transition:all 0.35s ease-in-out; will-change:color;}

		/*Full*/
		.button.full{background:var(--clear-blue);}
		.button.full:hover{box-shadow: 0 12px 12px 0 rgba(42, 145, 255, 0.3);}

			.button.full span{color:var(--white);}

		/*Border*/
		.button.border{background:var(--white);}
		.button.border:hover{box-shadow: 0 12px 12px 0 rgba(42, 145, 255, 0.3);}

			.button.border span{color:var(--clear-blue);}

	/*Toast*/
	.content_toast{width:100%; height:90px; position:fixed; z-index:10; top:0; left:0; background:var(--yellow); box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.2); transform:translateY(-100px); -webkit-transform:translateY(-100px); pointer-events:none; transition:all 0.35s ease-in-out; -webkit-transition:all 0.35s ease-in-out; will-change:transform;}
	.content_toast.show{transform:translateY(0); -webkit-transform:translateY(0); pointer-events:all;}

		.content_toast .center_content{padding:0 60px; height:100%; display:flex; justify-content: space-between; align-items:center;}

			/*Text*/
			.content_toast .text{position:relative; font-weight:600; float:left; padding:3px 0 0 50px;}

				.content_toast .text::before{width:30px; height:30px; position:absolute; top:0; left:0; background:url(/static/media/ic_toast_notification.bd9143ee.svg) no-repeat; content:'';}

			/*Amount*/
			.content_toast .amount{font-weight:700; font-size:1.5rem; margin:0 80px 0 0;}

			/*Close*/
			.content_toast .btn_close_toast{width:12px; height:12px; position:absolute; right:60px; top:0; bottom:0; margin:auto; background:url(/static/media/ic_close.336ba552.svg) no-repeat; font-size:0;}

				.content_toast .btn_close_toast:hover{transform:scale(1.1); -webkit-transform:scale(1.1);}

/*LAYOUT*/
.row{width:100%; float:left;}

.center_content{width:1000px; margin:0 auto;}

/*NAVIGATION*/
.navigation {padding:45px 0;}

	.navigation .center_content{padding:0 60px;}

		/*Logo*/
		.navigation .logo{width:180px; height:30px; float:left; background:url(/static/media/bg_logo.16af4f2b.png) no-repeat; background-size:100% auto; font-size:0;}

		/*Links*/
		.navigation .content_links{float:right;}

			.navigation .content_links li{width:120px; display:inline-block; margin:0 20px 0 0;}
			.navigation .content_links li:last-child{margin:0;}

				.navigation .content_links li a{height:35px; display:block; border-radius:32px; text-align:center; background:transparent;}
				
				.navigation .content_links li a:hover,
				.navigation .content_links li.active a{background:var(--clear-blue);}

				.navigation .content_links li.active a{pointer-events:none;}

					.navigation .content_links li a span{display:inline-block; margin:7px 0 0; font-size:0.875rem; font-weight:700; color:var(--clear-blue); transition:all 0.35s ease-in-out; -webkit-transition:all 0.35s ease-in-out;}
					
					.navigation .content_links li a:hover span,
					.navigation .content_links li.active a span{color:var(--white);}

		/*Return*/
		.navigation .btn_return{float:right; font-weight:700; font-size:0.875rem; color:var(--clear-blue); padding:8px 0 0 50px; position:relative;}

			.navigation .btn_return::before{width:35px; height:35px; position:absolute; top:0; left:0; border-radius:30px; background:url(/static/media/ic_arrow_back.57554939.svg) no-repeat var(--white); background-position:center; box-shadow:0 2px 12px 0 rgba(0, 0, 0, 0.1); content:'';}


/*DASHBOARD*/
.dashboard{}

	/*Card*/
	.dashboard .card{padding:60px; margin:0 0 25px;}
	
		/*Information*/
		.dashboard .card .content_information{margin:0 0 25px;}

			/*Name*/
			.dashboard .card .content_information .content_name{width:350px; float:left; padding:0 20px 0 0;}

				/*Hello*/
				.dashboard .card .content_information .content_name .name{font-weight:400; font-size:1.750rem; margin:0 0 15px; word-wrap:break-word;}

					.dashboard .card .content_information .content_name .name span{font-weight:700;}

				/*Msg*/
				.dashboard .card .content_information .content_name .msg{font-weight:400; font-size:0.875rem;}

			/*Money*/
			.dashboard .card .content_information .content_money{width:calc(100% - 350px); float:left;}

				/*Number*/
				.dashboard .card .content_information .content_money .number{font-weight:300; font-size:4.000rem; margin:0 0 10px;}

				/*Msg*/
				.dashboard .card .content_information .content_money .msg{font-weight:400; font-size:0.875rem; color:var(--warm-grey); margin:0 0 3px;}
				.dashboard .card .content_information .content_money .msg:last-child{margin:0;}

					.dashboard .card .content_information .content_money .msg span{font-weight:700;}

			/*No Money*/
			.dashboard .card .content_information .content_no_money{width:400px; float:left;}

				/*Msg*/
				.dashboard .card .content_information .content_no_money .msg{font-weight:400; font-size:1.125rem; margin:0 0 30px; line-height:1.5;} 

				.dashboard .card .content_information .content_no_money .small{font-weight:400; font-size:0.875rem; color:var(--warm-grey);}

					.dashboard .card .content_information .content_no_money .small span{font-weight:700;}

		/*Transfer Money*/
		.dashboard .card .content_transfer_money{background:var(--light-blue-grey); border-radius:12px; padding:45px 25px;}

			/*Msg*/
			.dashboard .card .content_transfer_money .msg{width:175px; float:left; font-weight:400; font-size:1.5rem; line-height:1.35;}

			/*Form*/
			.dashboard .card .content_transfer_money .content_form{width:calc(100% - 175px); float:left; padding:0 0 0 50px;}

				/*Amount*/
				.dashboard .card .content_transfer_money .content_form .content_amount{width:350px; float:left;}

				/*Fix Amount*/
				.dashboard .card .content_transfer_money .content_form .content_fix_amount{margin:25px 0 0;}

					.dashboard .card .content_transfer_money .content_form .content_fix_amount li{width:130px; float:left; margin:0 15px 0 0; position:relative;}

		/*Button*/
		.dashboard .card .content_button{text-align:center; margin:55px 0 0;}

			.dashboard .card .content_button .button{width:330px;}

	/*Confirmation*/
	.dashboard .card.confirmation{}

		/*Col Left*/
		.dashboard .card.confirmation .col_left{width:45%; float:left;}

			/*Heading*/
			.dashboard .card.confirmation .col_left h3{font-weight:400; font-size:1.5rem;}

			/*Amount*/
			.dashboard .card.confirmation .col_left .amount{font-weight:300; font-size:4.000rem;}

			/*Title Data*/
			.dashboard .card.confirmation .col_left .title_data{font-size:1.125rem; color:var(--warm-grey); font-weight:400;}

			/*Data*/
			.dashboard .card.confirmation .col_left .content_data{margin:30px 0 0;}

				.dashboard .card.confirmation .col_left .content_data li{border-bottom:var(--soft-grey) 1px solid; padding:0 0 10px; margin:0 0 10px;}

					.dashboard .card.confirmation .col_left .content_data li .title{float:left; font-weight:400; font-size:0.875rem;}

					.dashboard .card.confirmation .col_left .content_data li .information{float:right; font-weight:700;}

		/*Col Right*/
		.dashboard .card.confirmation .col_right{width:45%; float:right;}

			/*Label*/
			.dashboard .card.confirmation .col_right label{font-weight:700; font-size:1.125rem; margin:0 0 35px;}

			/*Msg*/
			.dashboard .card.confirmation .col_right .msg{font-weight:400; color:var(--warm-grey); font-size:0.875rem; margin:25px 0 0; line-height:1.5;}

				.dashboard .card.confirmation .col_right .msg span{font-weight:700;}

		/*Buttons*/
		.dashboard .card.confirmation .content_button .button{width:210px;}
		.dashboard .card.confirmation .content_button .button:first-child{margin:0 35px 0 0;}

	/*Success*/
	.dashboard .card.success{}

		.dashboard .card.success .center_content{width:375px;}

			/*Information*/
			.dashboard .card.success .information{}

				/*Icon Animation*/
				.dashboard .card.success .information .content_animation{width:62px; height:62px; margin:0 auto;}
				
					/*Check*/
					.dashboard .card.success .information .icon{position:absolute; left:0; top:0; z-index:3;}

					/*Circle*/
					.dashboard .card.success .information .circle{width:62px; height:62px; background:var(--green); border-radius:32px; position:absolute; top:0; left:0; right:0; bottom:0; z-index:2;}

					/*Animation*/

						/*Circle*/
						.dashboard .card.success .information .active .circle{animation:circle_animation 5s infinite; -webkit-animation:circle_animation 5s infinite; transform-origin:center;}

							@-webkit-keyframes circle_animation {
								0% {
									-webkit-transform:scale(1);
								}
								8% {
									-webkit-transform:scale(1.2);
								}
								16% {
								    -webkit-transform:scale(1)
								}
								100% {
								    -webkit-transform:scale(1)
								}
							}
								 
							@-webkit-keyframes loader_animation {
								0% {
									transform:scale(1);
								}
								8% {
									transform:scale(1.2);
								}
								16% {
								    transform:scale(1)
								}
								100% {
								    transform:scale(1)
								}
							}
								 
							@keyframes loader_animation {
								0% {
									transform:scale(1);
								}
								8% {
									transform:scale(1.2);
								}
								16% {
								    transform:scale(1)
								}
								100% {
								    transform:scale(1)
								}
							}

						.dashboard .card.success .information .active .icon{animation:icon_animation 5s infinite; -webkit-animation:icon_animation 5s infinite; transform-origin:center; animation-delay:0.3s; -webkit-animation-delay:0.3s;}

							@-webkit-keyframes icon_animation {
								0% {
									-webkit-transform:scale(1);
								}
								8% {
									-webkit-transform:scale(1.2);
								}
								16% {
								    -webkit-transform:scale(1)
								}
								100% {
								    -webkit-transform:scale(1)
								}
							}
								 
							@keyframes loader_animation {
								0% {
									transform:scale(1);
								}
								8% {
									transform:scale(1.2);
								}
								16% {
								    transform:scale(1)
								}
								100% {
								    transform:scale(1)
								}
							}

				/*Heading*/
				.dashboard .card.success .information h3{text-align:center; font-weight:400; font-size:1.5rem; margin:25px 0 0;}

				/*Amount*/
				.dashboard .card.success .information .amount{font-weight:300; font-size:4rem; color:var(--green); text-align:center;}

				/*Data*/
				.dashboard .card.success .content_data{margin:30px 0 0;}

					.dashboard .card.success .content_data li{border-bottom:var(--soft-grey) 1px solid; padding:0 0 10px; margin:0 0 10px;}

						.dashboard .card.success .content_data li .title{float:left; font-weight:400; font-size:0.875rem;}

						.dashboard .card.success .content_data li .information{float:right; font-weight:700;}

				/*Button*/
				.dashboard .card.success .content_button .button{width:100%;}

	/*Activity*/
	.dashboard .card.activity{padding:60px 250px;}

		/*Heading*/
		.dashboard .card.activity h3{font-weight:700; letter-spacing:1px; padding:0 25px; box-sizing:border-box;}

		/*List*/
		.dashboard .card.activity .content_list{margin:20px 0 30px; border-bottom:var(--soft-grey) 1px solid; padding:0 0 30px;}
		.dashboard .card.activity .content_list:last-child{margin:20px 0 0; border:none; padding:0;}

			.dashboard .card.activity .content_list li{margin:0 0 15px; padding:0 25px; box-sizing:border-box;}
			.dashboard .card.activity .content_list li:last-child{margin:0;}

				.dashboard .card.activity .content_list li .date{font-weight:300; float:left;}

				.dashboard .card.activity .content_list li .amount{font-weight:700; color:var(--black); float:right;}

	/*Profile*/
	.dashboard .card.profile{}

		/*Col Left*/
		.dashboard .card.profile .col_left{width:45%; float:left;}

			/*Heading*/
			.dashboard .card.profile .col_left h3{font-weight:700; font-size:1.125rem; margin:0 0 35px;}

			/*Personal Information*/
			.dashboard .card.profile .col_left .content_information li{border-bottom:var(--soft-grey) 1px solid; padding:0 0 15px; margin:0 0 15px;}
			.dashboard .card.profile .col_left .content_information li:last-child{margin:0; border:none;}

				.dashboard .card.profile .col_left .content_information li .title{float:left; font-weight:400; font-size:0.875rem;}

				.dashboard .card.profile .col_left .content_information li .information{float:right; font-weight:700;}

		/*Col Right*/
		.dashboard .card.profile .col_right{width:45%; float:right;}

			/*Label*/
			.dashboard .card.profile .col_right label{font-weight:700; font-size:1.125rem; margin:0 0 35px;}

			/*Password*/
			.dashboard .card.profile .col_right .content_password{margin:0 0 25px;}

		/*External Links*/
		.dashboard .card.profile .content_external_links{border-top:var(--soft-grey) 1px solid; border-bottom:var(--soft-grey) 1px solid; padding:15px 0; margin:50px 0 0;}

			.dashboard .card.profile .content_external_links li{width:48%; float:left; position:relative;}
			.dashboard .card.profile .content_external_links li:last-child{float:right;}

				.dashboard .card.profile .content_external_links li::before{width:1px; height:25px; position:absolute; top:0; right:0; background:var(--soft-grey); content:'';} 
				.dashboard .card.profile .content_external_links li:last-child::before{display:none;}

				.dashboard .card.profile .content_external_links li a{color:var(--clear-blue); font-weight:400; position:relative;}

					.dashboard .card.profile .content_external_links li a::before{width:8px; height:11px; position:absolute; top:6px; right:40px; background:url(/static/media/ic_blue_arrow.c2156953.svg); content:''; transition:all 0.35s ease-in-out; -webkit-transition:all 0.35s ease-in-out; will-change:transform;}

					.dashboard .card.profile .content_external_links li a:hover::before{transform:translateX(5px); -webkit-transform:translateX(5px);}

/*ONBOARDING*/
.onboarding{}

	/*Center*/
	.onboarding .center_content{width:485px;}

	/*Navigation*/

		/*Logo*/
		.onboarding .navigation .logo{float:none; display:block; margin:0 auto;}

	/*Card*/
	.onboarding .card{padding:60px; min-height:600px; margin:0 0 35px;}

		/*Generics*/

			/*Headings*/
			.onboarding .card h3{font-size:1.5rem; font-weight:400; margin:0 0 35px;}

			/*Paragraph*/
			.onboarding .card p{font-weight:400; line-height:1.5; margin:0 0 20px;}

			/*Form*/

				/*Label*/
				.onboarding .card label{font-weight:400; font-size:0.875rem; padding:0 20px; box-sizing:border-box; margin:0 0 12px;}

				/*Input*/
				.onboarding .card input{background:var(--grey-02); font-weight:600; font-size:1rem;}

			/*Circle*/
			.circle-wrap{position:absolute; top:45px; right:30px; width:60px; height:60px; background:var(--grey); border-radius:50%;}

				.circle-wrap .circle .mask,
				.circle-wrap .circle .fill{width:60px; height:60px; position:absolute; border-radius: 50%;}

				.circle-wrap .circle .mask{clip:rect(0px, 60px, 60px, 30px);}

				.circle-wrap .circle .mask .fill {clip:rect(0px, 30px, 60px, 0px); background-color:var(--clear-blue);}

					/*Animation Circle*/
						
						/*Step_02*/
						.step_02 .circle-wrap .circle .mask.full,
						.step_02 .circle-wrap .circle .fill{-webkit-animation: fill_step_02 ease-in-out 1.5s;animation: fill_step_02 ease-in-out 1.5s; transform: rotate(45deg);}

							@-webkit-keyframes fill_step_02 {
							  0% {
							    transform: rotate(0deg);
							  }
							  100% {
							    transform: rotate(45deg);
							  }
							}

							@keyframes fill_step_02 {
							  0% {
							    transform: rotate(0deg);
							  }
							  100% {
							    transform: rotate(45deg);
							  }
							}

						/*Step_03*/
						.step_03 .circle-wrap .circle .mask.full,
						.step_03 .circle-wrap .circle .fill{-webkit-animation:fill_step_03 ease-in-out 1.5s;animation:fill_step_03 ease-in-out 1.5s; transform: rotate(90deg);}

							@-webkit-keyframes fill_step_03 {
							  0% {
							    transform: rotate(45deg);
							  }
							  100% {
							    transform: rotate(90deg);
							  }
							}

							@keyframes fill_step_03 {
							  0% {
							    transform: rotate(45deg);
							  }
							  100% {
							    transform: rotate(90deg);
							  }
							}

						/*Step_04*/
						.step_04 .circle-wrap .circle .mask.full,
						.step_04 .circle-wrap .circle .fill{transform: rotate(90deg);}

						/*Step_05*/
						.step_05 .circle-wrap .circle .mask.full,
						.step_05 .circle-wrap .circle .fill{-webkit-animation:fill_step_05 ease-in-out 1.5s;animation:fill_step_05 ease-in-out 1.5s; transform: rotate(135deg);}

							@-webkit-keyframes fill_step_05 {
							  0% {
							    transform: rotate(90deg);
							  }
							  100% {
							    transform: rotate(135deg);
							  }
							}

							@keyframes fill_step_05 {
							  0% {
							    transform: rotate(90deg);
							  }
							  100% {
							    transform: rotate(135deg);
							  }
							}

						/*Step_06*/
						.step_06 .circle-wrap .circle .mask.full,
						.step_06 .circle-wrap .circle .fill{-webkit-animation:fill_step_06 ease-in-out 1.5s;animation:fill_step_06 ease-in-out 1.5s; transform: rotate(180deg);}

							@-webkit-keyframes fill_step_06 {
							  0% {
							    transform: rotate(135deg);
							  }
							  100% {
							    transform: rotate(180deg);
							  }
							}

							@keyframes fill_step_06 {
							  0% {
							    transform: rotate(135deg);
							  }
							  100% {
							    transform: rotate(180deg);
							  }
							}

				.circle-wrap .inside-circle{width:50px; height:50px; border-radius:50%; background:var(--white); line-height:50px; text-align:center;  margin-top:5px; margin-left:5px; position: absolute; z-index:100; font-weight:400;}

		/*Step 01*/

			/*Heading*/
			.onboarding .card.step_01 h3{font-weight:700;}

			/*Paragraph*/
			.onboarding .card.step_01 p{font-size:1.125rem; line-height:1.8; margin:0 0 35px;}

			/*Text*/
			.onboarding .card.step_01 span{font-weight:600; font-size:1.125rem}

			/*Button*/
			.onboarding .card.step_01 .button{margin:140px 0 0;}

		/*Step 02*/

			/*Form*/
			.onboarding .card.step_02 .content_form{margin:0 0 35px;}

				.onboarding .card.step_02 .content_form li{margin:0 0 40px; position:relative;}
				.onboarding .card.step_02 .content_form li:last-child{margin:0;}

			/*Button*/
			.onboarding .card.step_02 .button{margin:35px 0 0;}

		/*Step 03*/

			/*Tips*/
			.onboarding .card.step_03 .tips{font-weight:700; text-align:center; margin:0 0 25px;}

			/*Ilustration Examples*/
			.onboarding .card.step_03 .content_examples li{width:50%; float:left; text-align:center; margin:0 0 10px;}

				/*Illustration*/
				.onboarding .card.step_03 .content_examples li figure{height:85px;}

					.onboarding .card.step_03 .content_examples li figure span{width:100%; height:100%; display:block;}

					.onboarding .card.step_03 .content_examples li figure #card_01 svg,
					.onboarding .card.step_03 .content_examples li figure #card_02 svg{transform:scale(1.2) translate3d(0px, 0px, 0px) !important;}

				/*Text*/
				.onboarding .card.step_03 .content_examples li .text{font-weight:600; line-height:1.3; padding:0 25px; box-sizing:border-box;}

			/*Buttons*/
			.onboarding .card.step_03 .content_buttons{margin:30px 0 0;}

				.onboarding .card.step_03 .content_buttons .button{width:170px;}
				.onboarding .card.step_03 .content_buttons .button.border{float:left;}
				.onboarding .card.step_03 .content_buttons .button.full{float:right; pointer-events:none; z-index:3;}
					
					/*File*/
					.onboarding .card.step_03 .content_buttons .upload{width:170px; float:right; cursor:pointer;}

						.onboarding .card.step_03 .content_buttons .button.full span{padding:0 30px 0 0; position:relative;}

							.onboarding .card.step_03 .content_buttons .button.full span::before{width:19px; height:15px; position:absolute; top:4px; right:0; background:url(/static/media/ic_camera.4c4afdf6.svg) no-repeat; content:'';}

						.onboarding .card.step_03 .content_buttons .upload input[type="file"]{opacity:0; position:absolute; top:0; left:0; cursor:pointer;}

		/*Step 04*/

			/*Image Results*/
			.onboarding .card.step_04 .content_img{margin:25px 0;}

			/*New Picture*/
			.onboarding .card.step_04 .new_picture{text-align:center; cursor:pointer; margin:0 0 50px;}

				.onboarding .card.step_04 .new_picture span{pointer-events:none; font-weight:700; color:var(--clear-blue); font-size:1.125rem; text-decoration:underline;}

				.onboarding .card.step_04 .new_picture input[type="file"]{width:100%; height:100%; opacity:0; position:absolute; top:0; left:0; cursor:pointer;}

		/*Step 05*/

			/*Form*/
			.onboarding .card.step_05 .content_form{margin:0 0 35px;}

				.onboarding .card.step_05 .content_form li{margin:0 0 40px; position:relative;}
				.onboarding .card.step_05 .content_form li:last-child{margin:0;}

					/*Show hide password*/
					.onboarding .card.step_05 .content_form li .show_hide_pass{top:41px;}

			/*Buttons*/
			.onboarding .card.step_05 .content_buttons{margin:30px 0 0;}

				.onboarding .card.step_05 .content_buttons .button{width:170px;}
				.onboarding .card.step_05 .content_buttons .button.border{float:left;}
				.onboarding .card.step_05 .content_buttons .button.full{float:right;}

		/*Step 06*/

			/*Overlay*/
			.onboarding .card.step_06::before{width:100%; height:125px; pointer-events:none; position:absolute; bottom:140px; left:0; z-index:3; background-image:linear-gradient(to bottom, rgba(255, 255, 255, 0) 11%, #ffffff); content:'';}

			/*Terms*/
			.onboarding .card.step_06 .content_terms{height:440px; overflow-x:hidden; overflow-y:scroll;}

			/*Buttons*/
			.onboarding .card.step_06 .content_buttons{margin:30px 0 0;}

				.onboarding .card.step_06 .content_buttons .button{width:170px;}
				.onboarding .card.step_06 .content_buttons .button.border{float:left;}
				.onboarding .card.step_06 .content_buttons .button.full{float:right;}

		/*Step 07*/

			/*Icon Animation*/
			.onboarding .card.step_07 .content_animation{width:62px; height:62px; margin:0 auto;}
			
				/*Check*/
				.onboarding .card.step_07 .content_animation .icon{position:absolute; left:0; top:0; z-index:3;}

				/*Circle*/
				.onboarding .card.step_07 .content_animation .circle{width:62px; height:62px; background:var(--green); border-radius:32px; position:absolute; top:0; left:0; right:0; bottom:0; z-index:2;}

				/*Animation*/

					/*Circle*/
					.onboarding .card.step_07 .content_animation.active .circle{animation:circle_animation 5s infinite; -webkit-animation:circle_animation 5s infinite; transform-origin:center;}

					.onboarding .card.step_07 .content_animation.active .icon{animation:icon_animation 5s infinite; -webkit-animation:icon_animation 5s infinite; transform-origin:center; animation-delay:0.3s; -webkit-animation-delay:0.3s;}

			/*Heading*/
			.onboarding .card.step_07 h3{font-weight:700; font-size:1.5rem; text-align:center; margin:45px 0 0;}	
			
			.onboarding .card.step_07 h4{font-weight:600; font-size:1.5rem; text-align:center; line-height:1.5; margin:20px 0 0;}

			/*Paragraph*/
			.onboarding .card.step_07 p{font-weight:400; font-size:1.250rem; text-align:center; margin:40px 0 100px;}					

/*Media Query Mobile General*/
@media (max-width: 1000px) {

	/*GENERICS*/

		/*Toast*/

			.content_toast .center_content{padding:0 15px;}

				/*Text*/
				.content_toast .text{font-size:0.875rem; padding:3px 0 0 40px; margin:0 20px 0 0;}

					.content_toast .text::before{bottom:-5px; margin:auto;}

				/*Amount*/
				.content_toast .amount{width:200px; margin:0 30px 0 0;}

				/*Close*/
				.content_toast .btn_close_toast{right:15px;}


	/*LAYOUT*/
	.center_content{width:100%; float:left;}


	/*DASHBOARD*/

		/*Navigation*/
		.dashboard .navigation {padding:40px 0 10px;}

			.dashboard .navigation .center_content{padding:0 15px;}

				/*Logo*/
				.dashboard .navigation .logo{float:none; margin:0 auto; display:block;}

				/*Links*/
				.dashboard .navigation .content_links{width:100%; margin:25px 0 0; text-align:center;}

					.dashboard .navigation .content_links li{width:105px; margin:0;}

						.navigation .content_links li a span{font-size:0.813rem;}

				/*Return*/
				.dashboard .navigation .btn_return{font-size:0; padding:0; position:absolute; width:60px; height:60px; right:25px; bottom:-66px; z-index:3;}

					.dashboard .navigation .btn_return::before{width:60px; height:60px; background:url(/static/media/ic_arrow_back_mobile.730ef9b1.svg) no-repeat var(--white);}


		/*Card*/
		.dashboard .card{padding:35px 0 0; margin:0; border-radius:40px 40px 0 0;}
		
			/*Information*/
			.dashboard .card .content_information{text-align:center;}

				/*Name*/
				.dashboard .card .content_information .content_name{width:100%; padding:0 10px;}

				/*Money*/
				.dashboard .card .content_information .content_money{width:100%;}

				/*No Money*/
				.dashboard .card .content_information .content_no_money{width:100%;}

			/*Transfer Money*/
			.dashboard .card .content_transfer_money{border-radius:40px 40px 0 0; padding:25px 25px 110px;}

				/*Msg*/
				.dashboard .card .content_transfer_money .msg{width:100%; text-align:center; margin:0 0 20px;}

				/*Form*/
				.dashboard .card .content_transfer_money .content_form{width:100%; float:left; padding:0;}

					/*Amount*/
					.dashboard .card .content_transfer_money .content_form .content_amount{width:100%;}

					/*Fix Amount*/
					.dashboard .card .content_transfer_money .content_form .content_fix_amount li{width:48%; float:left; margin:0 0 10px;}
					.dashboard .card .content_transfer_money .content_form .content_fix_amount li:nth-child(even){float:right;}

			/*Button*/
			.dashboard .card .content_button{height:105px; position:fixed; bottom:0; left:0; z-index:10; margin:0; padding:0 25px;}

				.dashboard .card .content_button::before{width:100%; height:100%; position:absolute; top:0; left:0; z-index:2; opacity:0.6; background-image:linear-gradient(to bottom, transparent, var(--black)); content:'';}

				.dashboard .card .content_button .button{width:100%; z-index:3; position:relative; margin:30px 0 0;}

		/*Confirmation*/
		.dashboard .card.confirmation{padding:35px 25px; margin:25px 0 0;}

			/*Col Left*/
			.dashboard .card.confirmation .col_left{width:100%;}

			/*Col Right*/
			.dashboard .card.confirmation .col_right{width:100%;}

				/*Label*/
				.dashboard .card.confirmation .col_right label{display:none;}

				/*Msg*/
				.dashboard .card.confirmation .col_right .msg{text-align:center;}

			/*Buttons*/
			.dashboard .card.confirmation .content_button{position:relative; height:auto; padding:0;}

				.dashboard .card.confirmation .content_button::before{display:none;}
			
				.dashboard .card.confirmation .content_button .button{width:150px;}
			
				.dashboard .card.confirmation .content_button .button:first-child{margin:0 10px 0 0;}

		/*Success*/
		.dashboard .card.success{padding:35px 25px 90px; margin:25px 0 0;}

			.dashboard .card.success .center_content{width:100%;}

				/*Button*/
				.dashboard .card.success .content_button{position:relative; height:auto; padding:0;}

					.dashboard .card.success .content_button::before{display:none;}

		/*Activity*/
		.dashboard .card.activity{padding:35px 25px;}

			/*Heading*/
			.dashboard .card.activity h3{padding:0;}

			/*List*/
			.dashboard .card.activity .content_list li{padding:0;}

		/*Profile*/
		.dashboard .card.profile{padding:35px 25px;}

			/*Col Left*/
			.dashboard .card.profile .col_left{width:100%;}

			/*Col Right*/
			.dashboard .card.profile .col_right{width:100%;}

			/*Buttons*/
			.dashboard .card.profile .content_button{position:relative; height:auto; padding:0;}

				.dashboard .card.profile .content_button::before{display:none;}

			/*External Links*/
			.dashboard .card.profile .content_external_links{border:none; padding:0; margin:30px 0 20px;}

				.dashboard .card.profile .content_external_links li{width:100%; border-bottom:var(--soft-grey) 1px solid; padding:0 0 15px; margin:0 0 15px;}
				.dashboard .card.profile .content_external_links li:last-child{border:none; padding:0; margin:0;}

					.dashboard .card.profile .content_external_links li::before{display:none;}

						.dashboard .card.profile .content_external_links li a::before{right:0;}

						.dashboard .card.profile .content_external_links li a:hover::before{transform:translateX(0); -webkit-transform:translateX(0);}


	/*ONBOARDING*/

		/*Center*/
		.onboarding .center_content{width:100%;}

		/*Navigation*/

			/*Logo*/
			.onboarding .navigation .logo{float:none; margin:0 auto; display:block;}

		/*Card*/
		.onboarding .card{min-height:calc(100vh - 120px); padding:35px 25px 50px; margin:0; border-radius:40px 40px 0 0;}

			/*Generics*/

				/*Circle*/
				.circle-wrap{width:36px; height:36px; top:33px;}

					.circle-wrap .circle .mask,
					.circle-wrap .circle .fill{width:36px; height:36px;}

					.circle-wrap .circle .mask{clip:rect(0px, 36px, 36px, 18px);}

					.circle-wrap .circle .mask .fill {clip:rect(0px, 18px, 36px, 0px);}

					.circle-wrap .inside-circle{width:28px; height:28px; line-height:28px; margin-top:4px; margin-left:4px; font-size:0.750rem;}

			/*Step 01*/

				/*Button*/
				.onboarding .card.step_01 .button{margin:90px 0 0;}

			/*Step 02*/

				/*Form*/

					.onboarding .card.step_02 .content_form li{margin:0 0 25px;}

			/*Step 03*/

				/*Ilustration Examples*/
			
					/*Text*/
					.onboarding .card.step_03 .content_examples li .text{font-size:0.875rem; padding:0 10px; margin:5px 0 0;}

				/*Buttons*/
				.onboarding .card.step_03 .content_buttons{overflow:hidden;}

					.onboarding .card.step_03 .content_buttons .button{width:140px;}
						
						/*File*/
						.onboarding .card.step_03 .content_buttons .upload{width:170px; float:right; cursor:pointer;}

						.onboarding .card.step_03 .content_buttons .button.full{width:100%;}

			/*Step 05*/

				.onboarding .card.step_05 p br{display:none;}

				/*Form*/

					.onboarding .card.step_05 .content_form li{margin:0 0 25px;}

				/*Buttons*/

					.onboarding .card.step_05 .content_buttons .button{width:150px;}

			/*Step 06*/

				/*Overlay*/
				.onboarding .card.step_06::before{bottom:auto; top:345px; height:75px; -webkit-overflow-scrolling: touch;}

				/*Terms*/
				.onboarding .card.step_06 .content_terms{height:310px;}

				/*Buttons*/
					.onboarding .card.step_06 .content_buttons .button{width:150px;}

			/*Step 07*/

				/*Paragraph*/
				.onboarding .card.step_07 p{margin:40px 0 80px;}		
}
	
/* Spinner */
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader {
  color: var(--clear-blue);
  font-size: 10px;
  margin: 0 auto 25px auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}
.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* Help button */
.help-link {
  margin-top: 1rem !important;
  color: var(--clear-blue) !important;
}

/* Advance banner styles */
.content_banner {
  clear: both;
  margin: 2rem 0;
}

.content_banner .center_content {
  background: var(--clear-blue);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  float: none;
}

.content_banner .center_content .text {
  color: #fff;
}

.content_banner .center_content a {
  color: #fff;
  font-weight: bold;
}

/* Mobile request buttons */
@media (max-width: 1000px) {
  .dashboard .card .content_button {
    height: 135px;
  }

  .help-link {
    color: #fff !important;
    z-index: 3;
  }

  .whatssapp_logo_sub_container {
    z-index: 3;
  }

  .whatssapp_logo_container {
    margin-top: 1rem !important;
  }

  .content_banner {
    margin: 1rem;
  }

  .content_banner .center_content a {
    margin-left: 1rem;
  }
}

.step_04 .content_buttons .button {
  width: 170px;
}

.step_04 .content_buttons .button:first-child {
  float: left;
}

.step_04 .content_buttons .button:last-child {
  float: right;
}

.custom-logo {
  display: block;
  text-align: center;
}

.custom-logo img {
  width: 100px;
}

.forgot-password-btn {
  margin-bottom: 2rem;
  float: left;
  color: var(--clear-blue);
}

input:disabled {
  background: var(--grey-02);
  cursor: not-allowed;
}

input[type='radio']:disabled {
  cursor: not-allowed !important;
}

input[type='radio']:disabled + .label_radio {
  background: var(--grey-02);
}

input[type='radio']:disabled ~ .text {
  color: var(--warm-grey);
}

a.disabled {
  border: none;
  background: var(--grey) !important;
  cursor: not-allowed;
}

a.disabled:hover {
  box-shadow: none !important;
}

a.disabled span {
  color: var(--warm-grey) !important;
}

input.amount {
  font-size: 1.125rem;
}

input.amount::-webkit-input-placeholder {
  font-size: 0.875rem;
}

input.amount:-ms-input-placeholder {
  font-size: 0.875rem;
}

input.amount::-ms-input-placeholder {
  font-size: 0.875rem;
}

input.amount::placeholder {
  font-size: 0.875rem;
}

/* .navigation .center_content .logo, */
.navigation .center_content .content_links {
  height: 50px;
}

.navigation .center_content .content_links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.label-check .text {
  font-weight: 600;
  font-size: 0.75rem;
  color: var(--greyish);
  display: block;
  padding: 0 0 0 45px;
}

.label-check .text a {
  color: var(--clear-blue);
}

.warning .label-check span.label_check {
  background: var(--red);
}

.terms-container {
  width: 100%;
  height: 100%;
  display: flex;
}

.terms-close,
.close-modal {
  position: absolute;
  top: -5px;
  right: 0;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
}

.terms-frame {
  flex: 1 1;
  margin-top: 3rem;
}

.step_04 .circle-wrap .circle .mask.full,
.step_04 .circle-wrap .circle .fill {
  -webkit-animation: fill_step_05 ease-in-out 1.5s;
          animation: fill_step_05 ease-in-out 1.5s;
  transform: rotate(135deg);
}

.step_05 .circle-wrap .circle .mask.full,
.step_05 .circle-wrap .circle .fill {
  -webkit-animation: fill_step_06 ease-in-out 1.5s;
          animation: fill_step_06 ease-in-out 1.5s;
  transform: rotate(180deg);
}

.success-text {
  text-align: center;
  margin-top: 2rem;
}

/* Custom native select (inherited) */
.custom_native_select {
  background: var(--grey-02);
  height: 50px;
  float: left;
  border-radius: 8px;
}

.custom_native_select::before {
  width: 16px;
  height: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 15px;
  margin: auto;
  background: url(/static/media/bg_arrow_blue.a3ba521f.svg) no-repeat;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  will-change: transform;
  transform-origin: center;
  content: '';
}

.custom_native_select select {
  height: 50px;
  border: none;
  background: transparent;
  padding: 0 50px 0 20px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  color: var(--greyish);
}

.advance h3 {
  font-size: 1.5rem;
}

.advance.card .content_form li {
  margin-top: 2rem;
}

.advance.card label {
  font-weight: 400;
  font-size: 0.875rem;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 0 12px;
}

/*Input*/
.advance.card input {
  background: var(--grey-02);
  font-weight: 600;
  font-size: 1rem;
}

.form_error {
  text-align: center;
  margin-top: 2rem;
  color: var(--red);
}

.advance .content_button {
  margin-top: 32px !important;
}

.link {
  color: var(--clear-blue);
}

.full-page-center {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.blocked_period_message {
  z-index: 3;
}

.blocked_period_message span {
  background: var(--grey-02);
  border-radius: 100%;
  cursor: pointer;
  font-size: 10px;
  font-weight: bold;
  margin-left: 5px;
  padding: 4px 8px;
}

.blocked_period_message span:hover {
  background: var(--grey);
}

.salary-payment {
  padding: 1rem;
}

.salary-payment h1 {
  font-size: 1.8rem;
  font-weight: normal;
}

.salary-payment div {
  margin-top: 1.5rem;
}

.salary-payment a {
  color: var(--clear-blue);
}

.salary-payment button {
  border: none;
  border-radius: 20px;
  background-color: var(--clear-blue);
  color: #fff;
  font-size: 1rem;
  margin: 2rem auto 0 auto;
  display: block;
  padding: 0.8rem 1.6rem;
  outline: none;
}

.salary-modal {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  top: 40px;
  left: 0;
  right: 0;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  max-width: 400px;
  width: 80%;
}

.navigation {
  padding-bottom: 2rem;
}

.navigation .center_content:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation .center_content:last-child {
  display: flex;
  margin-top: 3rem;
}

.user_profile_card {
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05), 0 14px 12px 0 rgba(133, 106, 143, 0.1);
  padding: 1rem 2rem;
}

.user_profile_image {
  display: none;
  height: 40px;
  width: 40px;
}

.user_profile_image img {
  border-radius: 50%;
  width: 100%;
}

.user_profile_name {
  color: var(--clear-blue);
  font-weight: bold;
}

.programmed_requests {
  padding: 3rem 1rem !important;
  text-align: center;
}

.programmed_requests h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.programmed_requests .requests a {
  margin-left: 1rem;
}

.empty_requests a {
  margin-top: 1rem;
}

.programmed_request_wizard h1 {
  font-size: 1.5rem;
}

.programmed_request_wizard .content_form li {
  margin-top: 2rem;
}

.programmed_request_wizard label {
  padding-bottom: 1rem;
}

.programmed_request_wizard input {
  background: var(--grey-02);
  font-weight: 600;
  font-size: 1rem;
}

.programmed_request_wizard .content_button {
  margin-top: 3rem !important;
}

.programmed_request_wizard .messages {
  margin-top: 1rem;
}

.programmed_requests .buttons,
.programmed_request_wizard .content_button {
  margin-top: 2rem !important;
  display: flex;
  justify-content: center;
}

/* Loading button */
.loading_btn,
.disabled_full_btn {
  border-color: #94c8ff;
  background: #94c8ff !important;
  cursor: default;
}

.loading_btn:hover,
.disabled_full_btn:hover {
  box-shadow: none !important;
}

.loading_btn span {
  margin-right: 0.8rem;
}

.disabled_border_btn {
  border-color: #f4f4f4;
  background: #f4f4f4 !important;
  cursor: default;
}

.disabled_border_btn:hover {
  box-shadow: none !important;
}

.disabled_border_btn span:last-child {
  color: #ccc !important;
}

.wizard_error_msg {
  color: var(--red);
  margin-top: 1rem;
}

.date .advanced {
  font-weight: bold;
  margin-left: 0.5rem;
  font-size: 0.8rem;
  color: var(--clear-blue);
}

@media (max-width: 1000px) {
  .dashboard .card .content_transfer_money {
    padding-bottom: 170px;
  }

  .dashboard .card .content_button {
    height: 160px;
  }

  .request_button_message {
    background: #fff;
    border-radius: 25px;
    padding: 1rem 2rem;
  }

  .navigation .center_content:first-child {
    justify-content: space-around;
  }

  .navigation .center_content:first-child .logo {
    margin: 0;
  }

  .navigation .center_content:last-child {
    margin-top: 1rem;
  }

  .user_profile_card {
    padding: 0.25rem;
  }

  .user_profile_image {
    display: block;
    height: 30px;
    width: 30px;
  }

  .user_profile_name {
    display: none;
  }

  .programmed_requests .buttons,
  .programmed_request_wizard .content_button {
    flex-direction: column;
  }

  .programmed_request_wizard .content_button .button {
    width: 100% !important;
  }

  .programmed_requests .button:last-child,
  .programmed_request_wizard .content_button .button:last-child {
    margin-top: 1rem;
  }

  .content_button .button:last-child {
    order: -1 !important;
    margin-top: 0 !important;
  }

  .content_button .button:first-child {
    margin-top: 1rem !important;
  }
}

.icon_white {
  color: white;
  margin-right: 8px;
}

@media (min-width: 1000px) {
  .balance_home {
    padding-bottom: 30px !important;
  }
}

.whatssapp_logo_container {
  margin-top: 9px !important;
  display: flex;
  justify-content: flex-end;
}

.whatssapp_logo_sub_container {
  display: flex;
  justify-content: flex-end;
}

.whatssapp_logo {
  width: 60px;
  height: 60px;
}

.programmed_requests_balance_home {
  color: var(--white) !important;
  background: var(--clear-blue);
  height: 26px;
  border-radius: 32px;
  align-items: center;
  float: left;
}

@media (min-width: 1000px) {
  .programmed_requests_balance_home {
    margin-bottom: 6px !important;
    padding: 0 10px;
  }
}

@media (max-width: 1000px) {
  .programmed_requests_balance_home {
    margin: 0 6px 5px 6px !important;
    width: 98%;
  }
  .request_button_message {
    padding: 1rem 1rem;
  }
  .request_button_message_text {
    font-size: smaller;
  }
}

